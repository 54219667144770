import { AgeGroup, Center, Classroom, CommissionRate, Contract, Coupon, Program, Provider, ProviderFile, Seat, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function getUnreadBadge(email: string) {
  return {
    type: actionTypes.PROVIDER_UNREAD_BADGE,
    email,
  };
}

export function callbackGetUnreadBadge(badgeCount: number) {
  return {
    type: actionTypes.PROVIDER_UNREAD_CALLBACK,
    badgeCount,
  };
}

export function getNewToursCount(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_NEW_TOURS_COUNT,
    provider_id,
  };
}

export function callbackGetNewToursCount(toursCount: number) {
  return {
    type: actionTypes.PROVIDER_NEW_TOURS_CALLBACK,
    toursCount,
  };
}

export function findProviders(name: string, relationship: string | undefined) {
  return {
    type: actionTypes.PROVIDER_FIND,
    name,
    relationship,
  };
}

export function callbackFindProviders(providerList: Provider[], errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_FIND_CALLBACK,
    providerList,
    errorCode,
  };
}

export function readProvider(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ,
    provider_id,
  };
}

export function callbackReadProvider(provider: Provider, files: ProviderFile[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_CALLBACK,
    provider,
    files,
    errorCode,
  };
}

export function readProviderFamilies(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_FAMILIES,
    provider_id,
  };
}

export function callbackReadProviderFamilies(families: any, tours: any, tourRequests: any, leads: any, enrollments: any, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_FAMILIES_CALLBACK,
    families,
    tours,
    tourRequests,
    leads,
    enrollments,
    errorCode,
  };
}

export function readProviderClassrooms(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_CLASSROOMS,
    provider_id,
  };
}

export function readProviderCommission(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_COMMISSION,
    provider_id,
  };
}

export function callbackReadProviderCommission(commission: CommissionRate, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_COMMISSION_CALLBACK,
    commission,
    errorCode,
  };
}

export function callbackReadProviderClassrooms(classrooms: Classroom[], errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_CLASSROOMS_CALLBACK,
    classrooms,
    errorCode,
  };
}

export function readProviderAgeGroups(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_AGEGROUPS,
    provider_id,
  };
}

export function callbackReadProviderAgeGroups(ageGroups: AgeGroup[], errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_AGEGROUPS_CALLBACK,
    ageGroups,
    errorCode,
  };
}

export function readProviderPrograms(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_PROGRAMS,
    provider_id,
  };
}

export function callbackReadProviderPrograms(programs: Program[], contracts: Contract[], commission: any, ageGroupsByContractId: any, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_PROGRAMS_CALLBACK,
    programs,
    contracts,
    ageGroupsByContractId,
    commission,
    errorCode,
  };
}

export function readProviderSeats(provider_id: string, center_id: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_SEATS,
    provider_id,
    center_id,
  };
}

export function callbackReadProviderSeats(seats: Seat[], classrooms: Classroom[], centers: Center[], spots: WaitlistSpot[], mappings: Array<{ seat_id: string, spot_id: string }>, stripe: string | undefined, legupFees: boolean | undefined, availableSubsidies: Array<{ code: string, name: string }> | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_SEATS_CALLBACK,
    seats,
    classrooms,
    centers,
    spots,
    mappings,
    stripe,
    legupFees,
    availableSubsidies,
    errorCode,
  };
}

export function readProviderReport(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_REPORT,
    provider_id,
  };
}

export function callbackReadProviderReport(report: any, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_REPORT_CALLBACK,
    report,
    errorCode,
  };
}

export function listQuestions(provider_id: string, addCenter: boolean, includeInternal: boolean, form: "waitlist" | "deposit" | "both") {
  return {
    type: actionTypes.PROVIDER_QUESTIONS,
    provider_id,
    addCenter,
    includeInternal,
    form,
  };
}

export function callbackListQuestions(questions: any[], centerCount: number, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_QUESTIONS_CALLBACK,
    questions,
    centerCount,
    errorCode,
  };
}

export function readProviderUserAccess(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_USER_ACCESS,
    provider_id,
  };
}

export function callbackReadProviderUserAccess(users: any[], errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_USER_ACCESS_CALLBACK,
    users,
    errorCode,
  };
}

export function listPartners(states: string[]) {
  return {
    type: actionTypes.PROVIDER_LIST_PARTNERS,
    states,
  };
}

export function callbackListPartners(partners: any[], errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_LIST_PARTNERS_CALLBACK,
    partners,
    errorCode,
  };
}

export function listCoupons(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_LIST_COUPONS,
    provider_id,
  };
}

export function callbackListCoupons(coupons: Coupon[], waive_subsidies: boolean, subsidies: Array<{code: string, name: string}>, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_LIST_COUPONS_CALLBACK,
    coupons,
    waive_subsidies,
    subsidies,
    errorCode,
  };
}

export function waiveSubsidies(provider_id: string, waive_subsidies: boolean) {
  return {
    type: actionTypes.PROVIDER_NO_WAIVE_SUBSIDIES,
    provider_id,
    waive_subsidies,
  };
}

export function callbackNoWaiveSubsidies(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_NO_WAIVE_SUBSIDIES_CALLBACK,
    success,
    errorCode,
  };
}

export function addProvider(provider: Provider) {
  return {
    type: actionTypes.PROVIDER_ADD,
    provider,
  };
}

export function callbackAddProvider(provider_id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_ADD_CALLBACK,
    provider_id,
    errorCode,
  };
}

export function updateProvider(provider: Provider) {
  return {
    type: actionTypes.PROVIDER_UPDATE,
    provider,
  };
}

export function callbackUpdateProvider(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function updateOnboarding(provider_id: string, state: string, reason: string | undefined) {
  return {
    type: actionTypes.PROVIDER_UPDATE_ONBOARDING,
    provider_id,
    state,
    reason,
  };
}

export function callbackUpdateOnboarding(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_UPDATE_ONBOARDING_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterStates(provider_id: string, centerStates: any[]) {
  return {
    type: actionTypes.PROVIDER_UPDATE_CENTER_STATES,
    provider_id,
    centerStates,
  };
}

export function callbackUpdateCenterStates(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_UPDATE_CENTER_STATES_CALLBACK,
    success,
    errorCode,
  };
}

export function signUpUser(provider_id: string, email: string, phone_number: string, first_name: string, last_name: string, sendEmail: boolean, notification: any) {
  return {
    type: actionTypes.PROVIDER_SIGNUP_USER,
    provider_id,
    email,
    phone_number,
    first_name,
    last_name,
    sendEmail,
    notification,
  };
}

export function callbackSignUpUser(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_SIGNUP_USER_CALLBACK,
    success,
    errorCode,
  };
}

export function removeUser(provider_id: string, email: string, deleteUser: boolean) {
  return {
    type: actionTypes.PROVIDER_REMOVE_USER,
    provider_id,
    email,
    deleteUser,
  };
}

export function callbackRemoveUser(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_REMOVE_USER_CALLBACK,
    success,
    errorCode,
  };
}

export function stripeAuthorizeUrl(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_STRIPE_AUTHORIZE,
    provider_id,
  };
}

export function callbackStripeAuthorizeUrl(urls: Array<{ provider_id: string, url?: string }>, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_STRIPE_AUTHORIZE_CALLBACK,
    urls,
    errorCode,
  };
}

export function readActionItems(provider_id: string, center_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_ACTION_ITEMS,
    provider_id,
    center_id,
  };
}

export function updateActionItems(data) {
  return {
    type: actionTypes.PROVIDER_UPDATE_ACTION_ITEMS,
    data,
  };
}

export function callbackReadActionItems(data: any, success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_ACTION_ITEMS_CALLBACK,
    data,
    success,
    errorCode,
  };
}

export function readProviderFiles(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_READ_FILES,
    provider_id,
  };
}

export function callbackReadProviderFiles(files: ProviderFile[], success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_READ_FILES_CALLBACK,
    files,
    success,
    errorCode,
  };
}

export function addProviderFile(provider_id: string, file: ProviderFile) {
  return {
    type: actionTypes.PROVIDER_ADD_FILE,
    provider_id,
    file,
  };
}

export function callbackAddProviderFile(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROVIDER_ADD_FILE_CALLBACK,
    success,
    errorCode,
  };
}

export function incrementUnreadMessages() {
  return {
    type: actionTypes.PROVIDER_INCREMENT_UNREAD_MESSAGES,
  };
}

export function confirmFeesStructure(provider_id: string) {
  return {
    type: actionTypes.PROVIDER_CONFIRM_FEES_STRUCTURE,
    provider_id,
  };
}

export function callbackConfirmFeesStructure(success: boolean) {
  return {
    type: actionTypes.PROVIDER_CONFIRM_FEES_STRUCTURE_CALLBACK,
    success,
  };
}
