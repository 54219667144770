import { Center, Child, Parent } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function findItem(provider_id: string, name: string) {
  return {
    type: actionTypes.SEARCH_ITEM,
    provider_id,
    name,
  };
}

export function callbackFindItem(centers: Center[], parents: Parent[], children: Child[], moreItems: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEARCH_ITEM_CALLBACK,
    centers,
    parents,
    children,
    moreItems,
    errorCode,
  };
}

export function findEmail(email: string, includePhone: boolean, types: string[]) {
  return {
    type: actionTypes.SEARCH_EMAIL,
    email,
    includePhone,
    types,
  };
}

export function callbackFindEmail(emails: string[], moreItems: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEARCH_EMAIL_CALLBACK,
    emails,
    moreItems,
    errorCode,
  };
}
