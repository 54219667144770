import { DashboardTip, User } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readUser(user_id: string, call_kinside?: boolean) {
  return {
    type: actionTypes.USER_READ,
    user_id,
    call_kinside,
  };
}

export function callbackReadUser(user: User, errorCode: string | undefined) {
  return {
    type: actionTypes.USER_READ_CALLBACK,
    user,
    errorCode,
  };
}

export function readTips(user_id: string, groups: string) {
  return {
    type: actionTypes.USER_READ_TIPS,
    user_id,
    groups,
  };
}

export function callbackReadTips(tips: DashboardTip[], errorCode: string | undefined) {
  return {
    type: actionTypes.USER_READ_TIPS_CALLBACK,
    tips,
    errorCode,
  };
}

export function readNotifications(email: string, date: Date) {
  return {
    type: actionTypes.USER_NOTIFICATIONS,
    email,
    date,
  };
}

export function callbackReadNotifications(notifications: any, errorCode: string | undefined) {
  return {
    type: actionTypes.USER_NOTIFICATIONS_CALLBACK,
    notifications,
    errorCode,
  };
}

export function updateUser(user: User, avatar: string | undefined) {
  return {
    type: actionTypes.USER_UPDATE,
    user,
    avatar,
  };
}

export function callbackUpdateUser(success: boolean, avatarUrl: string | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.USER_UPDATE_CALLBACK,
    success,
    avatarUrl,
    errorCode,
  };
}

export function setFavorite(user_id: string, category: string, item: string, favorite: boolean) {
  return {
    type: actionTypes.USER_SET_FAVORITE,
    user_id,
    category,
    item,
    favorite,
  };
}

export function callbackSetFavorite(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.USER_SET_FAVORITE_CALLBACK,
    success,
    errorCode,
  };
}

export function getInteraction(user_id: string, area: string, prefix: string | undefined) {
  return {
    type: actionTypes.USER_GET_INTERACTION,
    user_id,
    area,
    prefix,
  };
}

export function callbackGetInteraction(interactions: string[], errorCode: string | undefined) {
  return {
    type: actionTypes.USER_GET_INTERACTION_CALLBACK,
    interactions,
    errorCode,
  };
}

export function setInteraction(user_id: string, area: string, feature: string, timestamp: Date) {
  return {
    type: actionTypes.USER_SET_INTERACTION,
    user_id,
    area,
    feature,
    timestamp,
  };
}

export function callbackSetInteraction(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.USER_SET_INTERACTION_CALLBACK,
    success,
    errorCode,
  };
}
