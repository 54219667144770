import { AuthToken } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function signInTokenProvider(code: string, group: string, name: string, email: string, partner_id: string | undefined) {
  return {
    type: actionTypes.SIGN_IN_TOKEN,
    code,
    group,
    name,
    email,
    partner_id,
  };
}
export function callbackSignInTokenProvider(authToken: AuthToken, accepted_terms: Date, last_login: Date, brand: { id: string, name: string, logo_url: string } | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.SIGN_IN_TOKEN_CALLBACK,
    authToken,
    accepted_terms,
    last_login,
    brand,
    errorCode,
  };
}

export function acceptTerms() {
  return {
    type: actionTypes.ACCEPT_TERMS,
  };
}

export function callbackAcceptTerms(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.ACCEPT_TERMS_CALLBACK,
    success,
    errorCode,
  };
}
