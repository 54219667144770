import { Child, Contract, Parent, Program, Seat, SeatAnalysis, SeatHistory, Tour, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readSeat(seat_id: string) {
  return {
    type: actionTypes.SEAT_READ,
    seat_id,
  };
}

export function callbackReadSeat(seat: Seat, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_READ_CALLBACK,
    seat,
    errorCode,
  };
}

export function addSeat(classroom_id: string, seat: Seat) {
  return {
    type: actionTypes.SEAT_ADD,
    classroom_id,
    seat,
  };
}

export function callbackAddSeat(success: boolean, seat_id: string | undefined, seatAnalysis: SeatAnalysis, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_ADD_CALLBACK,
    success,
    seat_id,
    seatAnalysis,
    errorCode,
  };
}

export function updateSeat(seat: Seat) {
  return {
    type: actionTypes.SEAT_UPDATE,
    seat,
  };
}

export function callbackUpdateSeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function offerSeat(seat_id: string, child_id: string, legupFamily: boolean) {
  return {
    type: actionTypes.SEAT_OFFER,
    seat_id,
    child_id,
    legupFamily,
  };
}

export function callbackOfferSeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_OFFER_CALLBACK,
    success,
    errorCode,
  };
}

export function offerFamilySeat(seat_id: string, contract_id: string, tour: Tour | undefined, state: string,
  notes: string | undefined, child: Child, parent: Parent, start_date: Date | undefined, expire_date: Date | undefined,
  reminder_date: Date | undefined, discount_percentage: boolean, discount_amount: number | undefined,
  discount_refundable_deposit: number | undefined, discount_reason: string | undefined) {
  return {
    type: actionTypes.SEAT_OFFER_FAMILY,
    seat_id,
    contract_id,
    tour,
    state,
    notes,
    child,
    parent,
    start_date,
    expire_date,
    reminder_date,
    discount_percentage,
    discount_amount,
    discount_refundable_deposit,
    discount_reason,
  };
}

export function offerFamilySeatReset() {
  return {
    type: actionTypes.SEAT_OFFER_FAMILY_RESET,
  };
}

export function callbackOfferFamilySeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_OFFER_FAMILY_CALLBACK,
    success,
    errorCode,
  };
}

export function updateSeatStates(updates: any[], mailProvider: boolean, mailForm: boolean) {
  return {
    type: actionTypes.SEAT_UPDATE_STATES,
    updates,
    mailProvider,
    mailForm,
  };
}

export function callbackUpdateSeatStates(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_UPDATE_STATES_CALLBACK,
    success,
    errorCode,
  };
}

export function removeSeat(seat_id: string, reason: string) {
  return {
    type: actionTypes.SEAT_REMOVE,
    seat_id,
    reason,
  };
}

export function callbackRemoveSeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_REMOVE_CALLBACK,
    success,
    errorCode,
  };
}

export function pauseSeat(seat_id: string, pause: boolean, paused_until: Date | undefined) {
  return {
    type: actionTypes.SEAT_PAUSE,
    seat_id,
    pause,
    paused_until,
  };
}

export function callbackPauseSeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_PAUSE_CALLBACK,
    success,
    errorCode,
  };
}

export function offerLegUpFamilies(seat_id: string, offer: boolean) {
  return {
    type: actionTypes.SEAT_OFFER_LEGUP_FAMILIES,
    seat_id,
    offer,
  };
}

export function callbackOfferLegUpFamilies(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_OFFER_LEGUP_FAMILIES_CALLBACK,
    success,
    errorCode,
  };
}

export function splitSeat(seat_id: string, schedules: Array<{program: Program, contracts: Contract[]}>) {
  return {
    type: actionTypes.SEAT_SPLIT,
    seat_id,
    schedules,
  };
}

export function callbackSplitSeat(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_SPLIT_CALLBACK,
    success,
    errorCode,
  };
}

export function seatsSummary(partner_id: string | undefined, center_id: string | undefined, latitude: number | undefined, longitude: number | undefined) {
  return {
    type: actionTypes.SEAT_SUMMARY,
    partner_id,
    center_id,
    latitude,
    longitude,
  };
}

export function callbackSeatsSummary(directory: any, basics: any, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_SUMMARY_CALLBACK,
    directory,
    basics,
    errorCode,
  };
}

export function seatReminders() {
  return {
    type: actionTypes.SEAT_LIST_REMINDERS,
  };
}

export function callbackSeatReminders(reminders: any, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_LIST_REMINDERS_CALLBACK,
    reminders,
    errorCode,
  };
}

export function seatUpdateReminder(reminder_id: string, reminder_time: Date) {
  return {
    type: actionTypes.SEAT_UPDATE_REMINDER,
    reminder_id,
    reminder_time,
  };
}

export function callbackSeatUpdateReminder(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_UPDATE_REMINDER_CALLBACK,
    success,
    errorCode,
  };
}

export function seatsAdminStats() {
  return {
    type: actionTypes.SEAT_ADMIN_STATS,
  };
}

export function callbackSeatsAdminStats(stats: any, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_ADMIN_STATS_CALLBACK,
    stats,
    errorCode,
  };
}

export function actionableSeats(provider_id: string, center_id: string, admin: boolean) {
  return {
    type: actionTypes.SEAT_READ_ACTIONABLE,
    provider_id,
    center_id,
    admin,
  };
}

export function callbackActionableSeats(seats: Seat[], spots: WaitlistSpot[], mappings: any, stripe: string, legupFees: boolean, tourRequests: number, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_READ_ACTIONABLE_CALLBACK,
    seats,
    spots,
    mappings,
    stripe,
    legupFees,
    tourRequests,
    errorCode,
  };
}

export function readSpot(seat_id: string) {
  return {
    type: actionTypes.SEAT_READ_SPOT,
    seat_id,
  };
}

export function callbackReadSpot(spot: WaitlistSpot, errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_READ_SPOT_CALLBACK,
    spot,
    errorCode,
  };
}

export function readNotes(seat_id: string) {
  return {
    type: actionTypes.SEAT_READ_NOTES,
    seat_id,
  };
}

export function callbackReadNotes(history: SeatHistory[], errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_READ_NOTES_CALLBACK,
    history,
    errorCode,
  };
}

export function readNearHits(seat_id: string) {
  return {
    type: actionTypes.SEAT_READ_NEAR_HITS,
    seat_id,
  };
}

export function callbackReadNearHits(nearhits: any[], errorCode: string | undefined) {
  return {
    type: actionTypes.SEAT_READ_NEAR_HITS_CALLBACK,
    nearhits,
    errorCode,
  };
}
